<template>
    <div class="row cham-cong">
        <div class="col-md-12 col-xl-12 align-items-center">
            <div class="row">
                <div class="col-lg-12 col-xl-2 order-lg-1 order-xl-1">
                    <!-- profile summary -->
                    <div class="card mb-g rounded-top">
                        <div class="d-flex flex-row pb-3 pt-4 text-left pl-2 border-top-0 border-left-0 border-right-0">
                            <h4 class="mb-0 flex-1 text-dark fw-500">Thời gian </h4>
                        </div>
                        <div class="col-md-10">
                            <el-date-picker
                                class="w-100"
                                v-model="form.selectDate"
                                type="month"
                                placeholder="Chọn tháng"
                                @change="search"
                            >
                            </el-date-picker>
                        </div>
                        <div class="row no-gutters row-grid">
                            <div class="col-12 p-4">
                                <h5 class="mb-3  col-xs-6">
                                    <!-- <span class="d-inline-block width-size">Họ và tên: </span><span class="fw-700">{{ currentUser.name || (currentUser.user && currentUser.user.name) }}</span>
                                </h5>
                                <h5 class="mb-3 col-xs-6">
                                    <span class="d-inline-block width-size">Mã nhân sự:</span><span class="fw-700">{{  (currentUser.id || currentUser.user.id) + 100000  }}</span>
                                </h5>
                                <h5 class="mb-3 col-xs-6">
                                    <span class="d-inline-block width-size">Văn Phòng: </span><span class="fw-700">{{  office }}</span> -->
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-12 col-xl-10 order-lg-3 order-xl-2">
                    <div class="panel">
                        <div class="panel-container show">
                            <div class="panel-content">
                                <table class="table table-bordered">
                                    <thead>
                                    <tr class="bg-violet text-white ">
                                        <th>ID</th>
                                        <th>Văn phòng</th>
                                        <th>Chi tiêu</th>
                                        <th>Tháng trước</th>
                                        <th>Tháng này</th>
                                        <th>% Tỷ trọng doanh số trong khu vực</th>
                                        <th>Tốc độ tăng trưởng so với tháng trước </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="!checkData" >
                                            <td colspan="7" class="text-center">Không có thông tin</td>
                                        </tr>
                                        <tr v-else v-for="(report, index) in reports" :key="index" :class="report.bool ? 'bold' : ''">
                                            <td>{{ report.id }}</td>
                                            <td >{{ report.ten_van_phong }}</td>
                                            <td>
                                                <ul>
                                                    <li v-for="(item, i) in targets" :key="i"  class="mt-2">
                                                        <span>{{item}}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="(item, i) in report.bao_cao" :key="i"  class="mt-2">
                                                        <span>{{item.thang_truoc |formatVND}}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="(item, i) in report.bao_cao" :key="i"  class="mt-2">
                                                        <span>{{item.thang_nay |formatVND}}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="(item, i) in report.bao_cao" :key="i" class="mt-2" >
                                                        <span v-if="item.ty_trong">{{item.ty_trong}} %</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="(item, i) in report.bao_cao" :key="i"  class="mt-2">
                                                        <span v-if="item.toc_do_tang_truong">{{item.toc_do_tang_truong}} %</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import { GET_REPORT_GROWTH_GDKV } from "../../../core/services/store/finances/finances.module";

export default {
    name: "GrowthRateReport",
    data() {
        return {
            reports: [],
            form: {
                selectDate: null
            },
            targets: [
                'Doanh số',
                'Lợi nhuận'
            ],
            checkData: false
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Danh sách"},
            {title: "Tốc độ tăng trưởng khu vực", icon: 'far fa-money-bill'}
        ]);
        let lastMonth = moment().format('YYYY-MM-DD');
        this.form.selectDate = new Date(lastMonth);
        this.getData();
    },
    methods: {
        getData() {
            this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM');
            this.$store.dispatch(GET_REPORT_GROWTH_GDKV, {month: this.form.selectDate}).then((data)=>{
                this.reports = data.data;
                if (!data.data && !data.data.length) {
                    this.checkData = false;
                    return;
                }
                this.checkData = true;

            });
        },
        search() {
            this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM-DD');
            this.getData();
        }
    }
}
</script>

<style scoped>
.bg-violet {
    background-color: #472F92;
}
ul {
    list-style-type: none;

}
/*td {*/
/*    padding: 0px;*/
/*}*/
/*li {*/
/*    border-bottom: 1px solid;*/
/*    padding: 10px;*/
/*}*/
</style>